// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useCallback, useEffect } from 'react';
import { Layout } from 'antd';

import { Switch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SiderMenu from '../../components/layout/SiderMenu';
import Users from '../../containers/users/Users';
import Endpoints from '../../containers/endpoints/Endpoints';
import Security from '../../containers/security/Security';
import {
  APPS_ROUTE,
  DIRECTORIES_ROUTE,
  HOME_ROUTE,
} from '../../routers/route-names';
import { useCheckForSetupMode } from '../initial-setup/store/hooks';
import ProtectedRoute from '../ProtectedRoute';
import Settings from '../settings/Settings';
import Dashboard from '../dashboard/Dashboard';
import Directories from '../directories/Directories';
import getConfig from '../../appConfigUtils';
import classes from '../../styles/layout.module.less';
import Groups from '../groups/Groups';
import Applications from '../applications/Applications';
import { useQueryParams } from '../login/store/hooks';
import { QueryParamKey } from '../login/store/constants';
import { invalidSessionAction } from '../login/store/actions';
import { getActiveItemFromPath, useAppNavigation } from './hooks/navigation';

const {
  DIRECTORIES_ENABLED,
  APPLICATIONS_ENABLED,
  ENDPOINTS_ENABLED,
  EPCS_ENABLED,
} = getConfig();

const siderMenuProps = {
  siderProps: { collapsed: true, 'data-testid': 'app-sider' },
  menuProps: {
    selectable: true,
    disabled: false,
    'data-testid': 'app-sider-menu',
  },
};

const Home: React.FC = () => {
  useCheckForSetupMode();
  const dispatch = useDispatch();
  const params = useQueryParams();

  useEffect(() => {
    const urlTenantId = params.get(QueryParamKey.TENANT_ID);
    if (!urlTenantId) {
      dispatch(
        invalidSessionAction.request({
          storedURL: window.location.pathname + window.location.search,
          errorMsgKey: '',
        }),
      );
    }
  }, [dispatch, params]);

  const [activeMenuItem, setActiveMenuItem, handleItemClick] =
    useAppNavigation();

  const onMenuClick = useCallback(
    (menuInfo: { key: string }) => {
      handleItemClick(menuInfo.key);
    },
    [handleItemClick],
  );

  const history = useHistory();

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'POP' || history.action === 'PUSH') {
        setActiveMenuItem(
          getActiveItemFromPath(location.pathname) || HOME_ROUTE,
        );
      }
    });
  }, [activeMenuItem, setActiveMenuItem, history, onMenuClick]);

  return (
    <Layout className={classes.mainContainer} data-testid="home-layout">
      <SiderMenu
        siderProps={siderMenuProps.siderProps}
        menuProps={{
          ...siderMenuProps.menuProps,
          selectedKeys: [activeMenuItem],
          onClick: onMenuClick,
        }}
      />
      <Switch>
        {EPCS_ENABLED && (
          <ProtectedRoute exact path={HOME_ROUTE} component={Dashboard} />
        )}
        <ProtectedRoute path="/settings" component={Settings} />
        <ProtectedRoute path="/users" component={Users} />
        {ENDPOINTS_ENABLED && (
          <ProtectedRoute path="/endpoints" component={Endpoints} />
        )}
        <ProtectedRoute path="/security" component={Security} />
        {APPLICATIONS_ENABLED && (
          <ProtectedRoute path={APPS_ROUTE} component={Applications} />
        )}
        {DIRECTORIES_ENABLED && [
          <ProtectedRoute
            key="directories-page"
            exact
            path={DIRECTORIES_ROUTE}
            component={Directories}
          />,
          <ProtectedRoute
            key="groups-page"
            exact
            path={`${DIRECTORIES_ROUTE}/:directoryId/groups`}
            component={Groups}
          />,
        ]}
      </Switch>
    </Layout>
  );
};

export default Home;
