// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Banner, destroyBanners } from '@imprivata-cloud/components';
import { AuthnModule, EventType } from '@imprivata-cloud/authn';
import { ImprHeaders } from '@imprivata-cloud/common';
import { DIRECTORIES_ROUTE } from '../../routers/route-names';
import history from '../../routers/history';
import { errorMapping } from '../../utils/BannerConstants';
import {
  createSessionAction,
  stateSaveUsernameAction,
  validatePermissionAction,
} from './store/actions';
import { useLoginHook } from './store/hooks';
import SetTitle from '../../utils/DynamicTitleHelper';
import { tracer, workflowId } from '../../tracing';
import { client } from '../../api/client';
import { authn } from '../../api/endpoint-names';
import { saveToStorage } from '../../utils';
import { StorageKeys, TenantType } from './store/constants';
import TestTenantLabel from '../../components/layout/TestTenantLabel';
import { Headers } from '../../api/constants';
import { getTenantId } from '../utils';

import styles from './LoginLayout.module.less';

const LoginContainer: React.FC = () => {
  const [paramsAdded, setParamsAdded] = useState(true);
  const { t } = useTranslation();
  SetTitle(t('login.astra-title'));

  const [isTestTenant, setTestTenant] = useState(false);

  useEffect(() => {
    saveToStorage(StorageKeys.IMPR_TENANT_TYPE, '');
    client
      .post<void>(authn.GET_ORG_PREFERENCES, undefined, {
        headers: {
          [ImprHeaders.ImprTenantId]: getTenantId(),
        },
      })
      .then(res => {
        const tenantType = res.headers[Headers.ImprTenantType] as TenantType;
        saveToStorage(StorageKeys.IMPR_TENANT_TYPE, tenantType);
        setTestTenant(tenantType === TenantType.TEST);
      })
      .catch(e => {
        console.warn('Failed to get ' + Headers.ImprTenantType, e);
      });
  }, []);

  useEffect(() => {
    const paramsBefore = new URLSearchParams(window.location.search);
    paramsBefore.set('contextType', 'generic-app-login');
    paramsBefore.set('resourceType', 'admin-web-ui');
    paramsBefore.set('workflowId', tracer.getWorkflowId());
    history.push({ search: paramsBefore.toString() });

    setParamsAdded(true);

    return () => {
      const paramsAfter = new URLSearchParams(window.location.search);
      paramsAfter.delete('contextType');
      paramsAfter.delete('resourceType');
      paramsAfter.delete('workflowId');
      history.push({ search: paramsAfter.toString() });
    };
  }, []);

  const {
    permissionError,
    username,
    storedURL,
    hasPermission,
    errorToDisplay,
  } = useLoginHook();

  const dispatch = useDispatch();

  useEffect(() => {
    if (permissionError) {
      Banner({
        type: 'error',
        message: t('errors.UXID_ADMIN_NOTALLOWED', { username }),
        duration: 10,
        datatestid: 'permission-error',
      });
    }
  }, [permissionError, username, dispatch, t]);

  useEffect(() => {
    const destroy = () => {
      destroyBanners();
    };
    if (errorToDisplay && !errorToDisplay.includes('UXID_MULTIPLEUSERS')) {
      const errorType = errorMapping[errorToDisplay as never];
      Banner({
        type: errorType ? errorType : 'error',
        message: t(errorToDisplay as never),
        duration: 10,
        datatestid: 'error-message',
      });
    } else {
      destroy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorToDisplay, dispatch]);

  const location = useLocation();
  // home is Directories in post EPCS times
  const loggedInLandingRoute = DIRECTORIES_ROUTE + location.search;

  const tenantId = new URLSearchParams(location.search).get('tenantId');

  const authnKey = useMemo(() => permissionError, [permissionError]);

  return (
    <>
      {isTestTenant && <TestTenantLabel />}
      {hasPermission && <Redirect to={storedURL || loggedInLandingRoute} />}
      {(paramsAdded && tenantId && (
        <div className={styles.loginContainer}>
          <div className={styles.productNameContainer}>
            <div>{t('login.product-name.company-name')}</div>
            <div className={styles.productNameSmallText}>
              {t('login.product-name.product-name')}
            </div>
            <div className={styles.productNameAdministratorConsole}>
              {t('login.product-name.subsystem-name')}
            </div>
          </div>
          <div className={styles.authnContainer}>
            <div style={{ background: '#FFF' }}>
              <AuthnModule
                key={authnKey}
                tenantId={tenantId}
                onEvent={({ event, data }) => {
                  switch (event) {
                    case EventType.AUTHENTICATED:
                      if (data?.username) {
                        dispatch(stateSaveUsernameAction(data.username));
                      }
                      dispatch(
                        createSessionAction.success({
                          tenantId,
                          sessionId: 'is-logged-in',
                        }),
                      );
                      dispatch(validatePermissionAction.request({}));
                      break;
                  }
                }}
                requestConfig={{
                  clientName: 'admin-web',
                }}
                tracing={{
                  workflowId,
                  tracer,
                }}
              />
            </div>
          </div>
        </div>
      )) || <></>}
    </>
  );
};

export default LoginContainer;
